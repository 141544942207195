import {Col, Container, Row} from 'react-bootstrap'
import {NewsBlock, Paragraph} from '../../../types/paragraphs'
import ContentTitles from '../../shared/ContentTitles'
import GenericLink from '../../shared/Link'
import algoliasearch from 'algoliasearch/lite'
import {
  InstantSearch,
  Highlight,
  Configure,
  connectRefinementList,
  connectHits,
  connectCurrentRefinements,
} from 'react-instantsearch-dom'
import {Hit} from '@algolia/client-search'
import {AlgoliaResource, Response} from '../../../types/api-types'
import NewsSlider from './NewsSlider'
import {useTranslation} from 'next-i18next'
import striptags from 'striptags'
import NewsPreviewAlgolia from './NewsPreviewAlgolia'
import {useRouter} from 'next/router'
import {
  CurrentRefinementsProvided,
  RefinementListProvided,
} from 'react-instantsearch-core'

export interface ClearRefinementsItems {
  attribute: string
  label: string
  currentRefinement?: string[] | null
  items?: ItemsEntity[] | null
  id: string
  index: string
}
export interface ItemsEntity {
  label: string
}

const ClearRefinements = ({items, refine}: CurrentRefinementsProvided) => {
  const {t} = useTranslation()
  return (
    <button
      onClick={() => refine(items)}
      disabled={!items || !items.length}
      className={`${
        !items || !items.length
          ? 'bg-tuna-grey text-white'
          : 'bg-slate-grey text-dark-grey'
      } px-3 py-2 mr-2 font-weight-bolder toptitle d-flex align-items-center rounded border-0`}
    >
      {striptags(t('mainpage_newsblock_pill_all'))}
    </button>
  )
}

const RefinementList = ({
  items,
  isFromSearch,
  refine,
}: RefinementListProvided) => {
  const CustomClearRefinements = connectCurrentRefinements(ClearRefinements)
  return (
    <div className="d-flex mb-0">
      <CustomClearRefinements />
      {items.map(item => (
        <button
          className={`${
            item.isRefined
              ? 'bg-tuna-grey text-white'
              : 'bg-slate-grey text-dark-grey'
          } px-3 py-2 mx-2 text-uppercase font-weight-bolder toptitle d-flex align-items-center rounded border-0`}
          key={item.label}
          onClick={event => {
            event.preventDefault()
            refine(item.value)
          }}
        >
          {isFromSearch ? (
            <Highlight attribute="label" hit={item} />
          ) : (
            item.label
          )}
        </button>
      ))}
    </div>
  )
}

type HitProps = {
  hits: Hit<AlgoliaResource>[]
}

const Hits = ({hits}: HitProps) => {
  return (
    <>
      <ul className="list-unstyled news-resource-row">
        {hits.map(hit => (
          <NewsPreviewAlgolia key={hit.objectID} resource={hit} />
        ))}
      </ul>
      <div className="d-block d-lg-none">
        <NewsSlider blogHits={hits} />
      </div>
    </>
  )
}

type Props = {
  paragraph: Paragraph
  response: Response | null
  blockNewsResourcesAlgolia: Hit<AlgoliaResource>[] | null
  country: string
}
const algoliaClient = algoliasearch(
  `${process.env.NEXT_PUBLIC_ALGOLIA_APP_ID}`,
  `${process.env.NEXT_PUBLIC_ALGOLIA_API_KEY_BLOG_SORTED}`,
)
const News = ({
  response,
  paragraph,
  blockNewsResourcesAlgolia,
  country,
}: Props) => {
  const router = useRouter()
  const searchClient = {
    ...algoliaClient,
    search(requests: any) {
      if (
        !requests
          .map((el: any) => el.params)
          .flat()
          .find((el: any) =>
            el.facetFilters.find((ff: any) => ff?.[0].includes('main_topic')),
          )
      ) {
        return Promise.resolve({
          results: requests.map(() => ({
            ...blockNewsResourcesAlgolia,
          })),
        })
      }

      return algoliaClient.search(requests)
    },
  }
  const CustomRefinementList = connectRefinementList(RefinementList)
  const CustomHits = connectHits(Hits)

  const newsBlockData: NewsBlock = response?.included?.find(
    el => el.id === paragraph.id,
  )

  return (
    <section className=" m-auto overflow-hidden py-5 flex section--bg-grey">
      <InstantSearch
        indexName={`${process.env.NEXT_PUBLIC_ALGOLIA_BLOG_SORTED_INDEX}`}
        searchClient={searchClient}
      >
        <Configure
          facetFilters={[
            [`search_api_language:${router.locale}`],
            [`group_field_machine_name:${country}`],
            ['type:blog_article'],
          ]}
          hitsPerPage={4}
        />
        <Container fluid className="section-box py-3">
          <Row>
            <Col lg={5} className="py-lg-0">
              <ContentTitles
                toptitle={newsBlockData.attributes.field_toptitle}
                isToptitleCustomColor={true}
                title={newsBlockData.attributes.field_title}
                toptitleColorClass="text-red"
                extraHeadingClass="news-block__title"
              />
              <span className="d-block mb-3">
                {newsBlockData.attributes.field_text_long}
              </span>
            </Col>
          </Row>
          <Row>
            <Col className="justify-content-between d-flex align-items-center">
              <div className="d-none d-md-block">
                <CustomRefinementList attribute="main_topic" />
              </div>
              {newsBlockData.attributes.field_button ? (
                <div className=" d-none d-lg-flex">
                  <GenericLink
                    link={newsBlockData.attributes.field_button}
                    extraClass="btn btn-link"
                  >
                    {newsBlockData.attributes.field_button.title}
                  </GenericLink>
                </div>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col>
              <CustomHits key="blog_article"></CustomHits>
            </Col>
          </Row>
          {newsBlockData.attributes.field_button ? (
            <Row>
              <Col>
                <div className="d-flex d-lg-none">
                  <GenericLink
                    link={newsBlockData.attributes.field_button}
                    extraClass="btn btn-link"
                  >
                    {newsBlockData.attributes.field_button.title}
                  </GenericLink>
                </div>
              </Col>
            </Row>
          ) : null}
        </Container>
      </InstantSearch>
    </section>
  )
}

export default News
