import React from 'react'
import {Row} from 'react-bootstrap'
import Link from 'next/link'
import Image from 'next/image'

import {Hit} from '@algolia/client-search'
import {AlgoliaResource} from '../../../types/api-types'
import router from 'next/router'
import {useTranslation} from 'next-i18next'
import striptags from 'striptags'

type NewsSliderProps = {
  blogHits: Hit<AlgoliaResource>[]
}

const NewsSlider = ({blogHits}: NewsSliderProps) => {
  const {t} = useTranslation('')
  return (
    <Row className="position-relative news-block__carousel-wrapper">
      <div className="news-block__carousel d-flex  mt-3 ml-md-4 pb-5 pt-4 ">
        {blogHits?.map((hit, index) => {
          const hitType =
            hit.type === 'event'
              ? hit.field_event_type
              : hit.type ?? 'blog_article'

          let date
          if (hitType === 'blog_article' || hit.field_publication_date) {
            date = new Date(
              hit.field_publication_date * 1000,
            ).toLocaleDateString(router.locale, {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })
          } else if (hit.field_start_date) {
            date = new Date(hit.field_start_date * 1000).toLocaleDateString(
              router.locale,
              {day: 'numeric', month: 'long', year: 'numeric'},
            )
          }
          return (
            <div
              key={index}
              className="d-flex flex-column news-block__carousel__item pl-0 pr-0 relative bg-white"
            >
              <div className="">
                <div className=" ">
                  {hit.field_media_url ? (
                    <div className="news-block__carousel__item__image position-relative">
                      <Image
                        onDragStart={event => event.preventDefault()}
                        src={`${process.env.NEXT_PUBLIC_API_URL}${hit.field_media_url}`}
                        objectFit="cover"
                        layout="fill"
                        alt={hit.alt}
                      ></Image>
                      <div
                        className={`c-hit-preview__tag c-hit-preview__tag--${hitType} font-uppercase py-2 d-flex align-items-center`}
                      >
                        <svg
                          width="20"
                          height="18"
                          viewBox="0 0 20 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.25 0.75V14.25C0.25 15.9082 1.5918 17.25 3.25 17.25H16.75C18.4082 17.25 19.75 15.9082 19.75 14.25V6H15.25V0.75H0.25ZM1.75 2.25H13.75V14.25C13.75 14.7979 13.917 15.3076 14.1719 15.75H3.25C2.35645 15.75 1.75 15.1436 1.75 14.25V2.25ZM3.25 3.75V7.5H12.25V3.75H3.25ZM4.75 5.25H10.75V6H4.75V5.25ZM15.25 7.5H18.25V14.25C18.25 15.1436 17.6436 15.75 16.75 15.75C15.8564 15.75 15.25 15.1436 15.25 14.25V7.5ZM3.25 8.25V9.75H7V8.25H3.25ZM8.5 8.25V9.75H12.25V8.25H8.5ZM3.25 10.5V12H7V10.5H3.25ZM8.5 10.5V12H12.25V10.5H8.5ZM3.25 12.75V14.25H7V12.75H3.25ZM8.5 12.75V14.25H12.25V12.75H8.5Z"
                            fill="white"
                          />
                        </svg>{' '}
                        <span className="ml-2">{hit.main_topic}</span>
                      </div>
                    </div>
                  ) : null}

                  <div className="p-3">
                    <Link href={hit.url} locale={hit.search_api_language}>
                      <a className="h5 font-weight-light text-decoration-none text-dark-grey">
                        {hit.title}
                      </a>
                    </Link>

                    <hr />
                    <div className="">
                      {hit.field_first_name && hit.field_last_name ? (
                        <span className="toptitle">
                          {striptags(t('resource_by'))}{' '}
                          <a href="#" className="text-orange font-underline">
                            <u>
                              {hit.field_first_name} {hit.field_last_name}
                            </u>
                          </a>
                        </span>
                      ) : null}
                      {date ? (
                        <div className="toptitle">
                          {date} • {striptags(t('resource_readingtime'))}:{' '}
                          {hit.field_reading_duration}{' '}
                          {striptags(t('resource_minutes'))}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </Row>
  )
}

export default NewsSlider
