/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import {useRouter} from 'next/router'
import {useTranslation} from 'next-i18next'
import {AlgoliaResource} from '../../../types/api-types'
import striptags from 'striptags'
import RichText from '../../shared/RichText'

type Props = {
  resource: AlgoliaResource
  fullResourceData?: boolean
}

const NewsPreviewAlgolia = ({resource, fullResourceData = false}: Props) => {
  const {t} = useTranslation()
  const router = useRouter()

  let date
  if (resource.field_publication_date) {
    date = new Date(resource.field_publication_date * 1000).toLocaleDateString(
      router.locale,
      {day: 'numeric', month: 'long', year: 'numeric'},
    )
  } else if (resource.field_start_date) {
    date = new Date(resource.field_start_date * 1000).toLocaleDateString(
      router.locale,
      {day: 'numeric', month: 'long', year: 'numeric'},
    )
  }

  const resourceDescription = resource?.processed?.substring(0, 500) + '...'

  return (
    <Link href={resource.url} locale={resource.search_api_language}>
      <a
        className={`h-100 text-dark-grey  ${
          fullResourceData ? 'shadow-sm' : ''
        }`}
      >
        <div className="news-resources-preview h-100 d-flex flex-column bg-white">
          {resource.field_media_url ? (
            <div className="news-resources-preview__image overflow-hidden position-relative">
              <Image
                src={`${process.env.NEXT_PUBLIC_API_URL}${resource.field_media_url}`}
                objectFit="cover"
                layout="fill"
                alt={resource.alt}
              ></Image>
              <div
                className={`news-resources-preview__tag position-absolute font-uppercase py-2 d-flex align-items-center`}
              >
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.25 0.75V14.25C0.25 15.9082 1.5918 17.25 3.25 17.25H16.75C18.4082 17.25 19.75 15.9082 19.75 14.25V6H15.25V0.75H0.25ZM1.75 2.25H13.75V14.25C13.75 14.7979 13.917 15.3076 14.1719 15.75H3.25C2.35645 15.75 1.75 15.1436 1.75 14.25V2.25ZM3.25 3.75V7.5H12.25V3.75H3.25ZM4.75 5.25H10.75V6H4.75V5.25ZM15.25 7.5H18.25V14.25C18.25 15.1436 17.6436 15.75 16.75 15.75C15.8564 15.75 15.25 15.1436 15.25 14.25V7.5ZM3.25 8.25V9.75H7V8.25H3.25ZM8.5 8.25V9.75H12.25V8.25H8.5ZM3.25 10.5V12H7V10.5H3.25ZM8.5 10.5V12H12.25V10.5H8.5ZM3.25 12.75V14.25H7V12.75H3.25ZM8.5 12.75V14.25H12.25V12.75H8.5Z"
                    fill="white"
                  />
                </svg>{' '}
                <span className="ml-2">{resource.main_topic}</span>
              </div>
            </div>
          ) : null}

          <div className="p-4 d-flex flex-column flex-lg-grow-1">
            <span className="h5 font-weight-light">{resource.title}</span>
            {resource.processed && fullResourceData ? (
              <RichText extraClass="toptitle" html={resourceDescription} />
            ) : null}
            <hr />
            <div className="mt-auto d-flex flex-column">
              {resource.field_first_name && resource.field_last_name ? (
                <span className="toptitle">
                  {striptags(t('resource_by'))} {resource.field_first_name}{' '}
                  {resource.field_last_name}
                </span>
              ) : null}

              {date ? (
                <div className="toptitle ">
                  {date} • {striptags(t('resource_readingtime'))}:{' '}
                  {resource.field_reading_duration}{' '}
                  {striptags(t('resource_minutes'))}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </a>
    </Link>
  )
}

export default NewsPreviewAlgolia
